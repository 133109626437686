<template>
  <div :class="label">
    <span>
      <div class="d-block d-lg-inline">{{ label }}&nbsp;</div>
      <a
        href="#"
        @click.prevent="$bvModal.show(modalId)"
        v-if="dcFastChargers || level2Chargers"
      >
        see map&nbsp;&raquo;</a
      ></span
    >
    <b-modal
      v-if="dcFastChargers || level2Chargers"
      :id="modalId"
      size="lg"
      variant="dark"
      :title="`${this.vehicle.Year} ${this.vehicle.Make} ${this.vehicle.Model}`"
      hide-footer
    >
      <ChargingStationMap :vehicle="vehicle" />
    </b-modal>
  </div>
</template>
<script>
const ChargingStationMap = () => import("Components/ChargingStationMap");
export default {
  props: {
    vehicle: Object,
  },
  data() {
    const modalId = `chargers-modal-${this.vehicle.Id}${this.randomChars(3)}`;
    return {
      modalId,
    };
  },
  methods: {
    randomChars(length) {
      var result = "";
      var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },
  computed: {
    dcFastChargers() {
      return this.$store.getters.filterChargingLocations(
        this.vehicle,
        "dc_fast"
      )?.length;
    },
    level2Chargers() {
      return this.$store.getters.filterChargingLocations(this.vehicle, "level2")
        ?.length;
    },
    label() {
      const highThreshold = 25;
      const mediumThreshold = 4;
      if (!this.dcFastChargers && !this.level2Chargers) return "N/A";
      if (this.dcFastChargers >= highThreshold) return "high";
      if (this.dcFastChargers >= mediumThreshold) return "medium";
      return "low";
    },
  },
  components: { ChargingStationMap },
};
</script>
<style lang="scss" scoped>
div {
  display: inline;
}
::v-deep .modal-content {
  background-color: $dark-bg-color;
}
a {
  text-decoration: underline;
  color: $link-color-dark;
}
::v-deep .close {
  color: white !important;
}
</style>
